import { derived, writable } from "svelte/store";
import * as utils from "../utils";
import type { INotification, dropdownOptions } from "./interfaces";

type Callback = (arg?: any) => any;

function createNotificationsStore() {
    const { subscribe, set, update } = writable<INotification[]>([]);

    return {
        subscribe,
        update,
        set,
        pollNotifications: async (errorCallback?: Callback): Promise<void> => {
            // eslint-disable-next-line no-constant-condition
            while (true) {
                try {
                    const data = await utils.doXHR("/notifications", "GET", undefined, undefined, undefined, false, { Accept: "application/json" });
                    const response: INotification[] = JSON.parse(data.response);
                    set(response);
                }
                catch (error) {
                    if (errorCallback) {
                        errorCallback();
                    }
                }
                await new Promise(resolve => setTimeout(resolve, 5000));
            }
        }
    };
}

export const notificationStore = createNotificationsStore();

export const unreadNotificationStore = derived(notificationStore, $notificationStore => {
    return $notificationStore.filter((notification) => notification.read === false);
});

export const readNotificationStore = derived(notificationStore, $notificationStore => {
    return $notificationStore.filter((notification) => notification.read === true);
});

export const dropdownShowing = writable<dropdownOptions>(null);
