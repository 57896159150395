/*
===============================================================================
    Log
===============================================================================
*/

/**
 * Encodes a FormData string from the given data resource.
 * @param data An emumerable object to encode
 */
export function encodeFormData(data: any): string {
    function encode(item: object | string) {
        return encodeURIComponent(item.toString()).replace("%20", "+");
    }

    let name: string;
    let key: string;
    let value: string;

    data = data || {};
    const pairs: string[] = [];
    for (name in data) {
        if (Object.prototype.hasOwnProperty.call(data, name) && typeof (data[name]) !== "function") {
            key = encode(name);
            value = encode(data[name]);
            pairs.push(key + "=" + value);
        }
    }
    return pairs.join("&");
}

/*
 * Log an incident with the firehose.
 */
export function log(incident: string, payload: object = {}) {

    const payloadJson = JSON.stringify(payload);
    const incidentString = incident.toString();

    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", "/itg-handover/api/log", true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    resolve(xhr.responseText);
                } else {
                    reject(xhr.status);
                }
            }
        };
        const data = {
            incident: incidentString,
            payload: payloadJson,
        };
        xhr.send(encodeFormData(data));
    });
}


/*
===============================================================================
    Session Timeout
===============================================================================
*/

const sessionActivityTSKey = "ts_last_activity";
const sessionTimeoutMinutesMainKey = "session_timeout_minutes";

function getTimeoutMinutesMain(): number {
    return parseInt(getCookie(sessionTimeoutMinutesMainKey) || "0", 10);
}

/**
 * Starts the session timeout timer. This will look at the `session_timeout_minutes`
 * cookie to determine correct timeout duration.  If such a cookie does not exist,
 * this will return false and not start the timer.
 */
export function startInactivityTimeoutTimer(): boolean {
    const timeoutMinutes = getTimeoutMinutesMain();
    // patch `onstorage` to enable cross-tab synchronisation under IE11.. :
    try {
        window.onstorage = function() {}; // eslint-disable-line @typescript-eslint/no-empty-function
    } catch (ex) {
        console.warn("unable to patch onstorage for x-tab synchronisation");
    }
    if (timeoutMinutes) {
        // useful activity surrogate
        if (typeof MutationObserver !== "undefined") {  // if have MutationObserver support:
            const observer = new MutationObserver(updateLastActivityTS);
            observer.observe(document.body, { attributes: false, childList: true, subtree: true });
        } else {
            document.addEventListener("DOMSubtreeModified", updateLastActivityTS);
        }
        document.addEventListener("click", updateLastActivityTS);
        document.addEventListener("keydown", updateLastActivityTS);
        // trigger an activity update, as the timer will be started anew.
        // this prevents an old localStorage entry expiring a brand new session
        updateLastActivityTS();
        // Check every 5 seconds. This is set to a low amount since browsers
        // can be +/- a few ms per interval. If for example we set to 30
        // seconds, and the browser scheduled for 29 seconds, it would take
        // another ~ 30 seconds before the inactivity is detected.
        window.setInterval(maybeLogout, 5 * 1000);
    }
    return !!timeoutMinutes;
}
/**
 * maybeLogout checks whether the user has elapsed the configured number of
 * minutes of inactivity. If so, it logs out the user by navigating to the
 * logout endpoint.
 */
function maybeLogout() {
    const lastActivityMS = getLastActivityTS();
    // if last activity is less than (now - timeout_minutes_main), force logout
    const timeSinceLastActivityMS = Date.now() - lastActivityMS;
    const sessionTimeoutMainMS = getTimeoutMinutesMain() * 60 * 1000;
    if (timeSinceLastActivityMS >= sessionTimeoutMainMS) {
        forceLogout();
    }
}

/**
 * forceLogout forcefully navigates to the logout endpoint, ensuring that
 * the user's session is invalidated and any currently-displayed PHI is
 * removed.
 */
function forceLogout() {
    // remove any onBeforeUnload handlers -- we don't want a prompt to appear
    // a recognised purpose of this is to remove potential PHI
    if (window.onbeforeunload) {
        window.onbeforeunload = function() {}; // eslint-disable-line @typescript-eslint/no-empty-function
    }
    window.location.href = "/accounts/logout/";
}

// per-page transient activity tracker, used in case there is an issue
// accessing the more persistent localStorage (ie: if it is disabled by an
// admin policy)
let fallbackActivityTS = 0;

/**
 * getLastActivityTS returns the timestamp of the last observed vault activity.
 * This includes DOM updates as well as page navigation.
 *
 * It will use localStorage (persistent across tabs + browser reloads) if
 * possible, falling back to a per-page variable.
 */
function getLastActivityTS(): number {
    try {
        return parseInt(localStorage.getItem(sessionActivityTSKey) || "0", 10);
    } catch (ex) {
        return fallbackActivityTS;
    }
}

/**
 * updateLastActivityTS updates the last observed activity item with the
 * current timestamp.
 */
function updateLastActivityTS() {
    fallbackActivityTS = Date.now();
    try {
        localStorage.setItem(sessionActivityTSKey, `${fallbackActivityTS}`);
    } catch (ex) {
        // discarded, as fallback is already set in place as a first priority
    }
}

/**
 * Handler for initialising the session timeout timer. Call this to start the
 * timer on page load.
 */
export function init(): void {
    document.addEventListener("DOMContentLoaded", startInactivityTimeoutTimer);
}

/*
===============================================================================
    Misc
===============================================================================
*/

// {# CYD_RTRS_DI_54_10 Idle Sessions Timeout (FRON-259) #}
// from https://docs.djangoproject.com/en/1.9/ref/contrib/csrf/
export function getCookie(name: string): string | null {
    let cookieValue: string | null = null;
    if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");
        for (const cookie of cookies) {
            const cookieTrimmed = cookie.trim();
            // Does this cookie string begin with the name we want?
            if (cookieTrimmed.substring(0, name.length + 1) === (name + "=")) {
                cookieValue = decodeURIComponent(cookieTrimmed.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

/*
 * Test a string for not requiring CSRF information
 * Expected to pass in a HTTP method string, i.e. GET
 */
export function csrfSafeMethod(method: string): boolean {
    // these HTTP methods do not require CSRF protection
    switch (method) {
        case "GET":
        case "HEAD":
        case "OPTIONS":
        case "TRACE":
            return true;
        default:
            return false;
    }
}

const scriptElement = (document.querySelector("script[data-name=\"vault\"]")) as HTMLScriptElement;

/**
 * Retrieves the data attribute specified on the main "vault" script element.
 * @param key data-KEY for the required data-attribute
 */
export function getScriptData(key: string): string {
    if (!scriptElement) {
        throw new Error("could not locate script element");
    }
    return scriptElement.getAttribute("data-" + key) || "";
}

/**
 * Generates a UUID4 string
 * Source: https://gist.github.com/kaizhu256/2853704
 */
export function uuid4(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, _uuid4);
}

function _uuid4(cc: string): string {  // eslint-disable-line no-underscore-dangle
    const rr = Math.random() * 16 | 0;
    return (cc === "x" ? rr : (rr & 0x3 | 0x8)).toString(16);  // tslint:enable:no-bitwise
}

/**
 * Generates a human-friendly string representing the number of seconds remaining.
 * @param seconds number of seconds remaining (can be decimal)
 */
export function humanEta(seconds: number): string {
    switch (true) {
        case (!seconds):
            return "&nbsp;";
        case (seconds >= 120):
            return `About ${Math.ceil(seconds / 60)} minutes remaining`;
        case (seconds > 60):
            return `1 minute and ${Math.floor(seconds % 60)} seconds remaining`;
        default:
            return `${Math.ceil(seconds)} seconds remaining`;
    }
}

/**
 * humanDuration returns a human-friendly time string for the number of seconds given.
 * @param seconds number of seconds
 */
export function humanDuration(seconds: number): string {
    let fmtStr = "around ";
    const mins = Math.floor(seconds / 60);
    const secondsMod = Math.ceil(seconds % 60);
    if (mins > 0) {
        fmtStr += `${mins} minutes${secondsMod > 0 ? " and " : ""}`;
    }
    if (mins > 0 && secondsMod === 0) {
        return fmtStr;
    }
    if (secondsMod > 1) {
        fmtStr += `${secondsMod} seconds`;
    } else {
        return "less than a second";
    }
    return fmtStr;
}

let keepAliveInterval: ReturnType<typeof setInterval>;

/**
 * Sets a timer to "GET" the /session_keepalive endpoint at a regular interval.
 * Useful in situations where connections may be pruned at a fast rate, or when
 * session cookies may expire before activity has finished.
 * @param intervalSeconds how many seconds to elapse before refresh of session
 */
export function keepAlive(intervalSeconds: number, seriesTimer?: number | string): boolean {
    if (!keepAliveInterval) {
        keepAliveInterval = setInterval(() => {
            // required for uploads that could take longer than the session idle time
            console.debug(`keepAlive: triggered after ${intervalSeconds} seconds`);
            if (seriesTimer) {
                getJson(`/session_keepalive?series_id=${seriesTimer}`);
            }
            else {
                getJson("/session_keepalive");
            }
        }, intervalSeconds * 1000);  // every N seconds
        return true;
    }
    return false;
}

/**
 *
 * @param url URL endpoint to access (absolute or relative)
 * @param method HTTP Method to use, i.e. "POST" or "GET"
 * @param payload (optional) either string or FormData containing payload to push through
 * @param responseType (optional) can be either text, arraybuffer, or blob.
 */
export async function doXHR(
    url: string,
    method: string,
    payload?: string|FormData,
    responseType?: XMLHttpRequestResponseType,
    timeoutMS: number = 60 * 1000,
    synchronous = false,
    extraHeaders: {[key: string]: string} | null = null
): Promise<XMLHttpRequest> {
    const xhr = openXHR(url, method, responseType, timeoutMS, synchronous, extraHeaders);
    return sendXHR(xhr, payload);
}

/**
 * Create and open an XMLHttpRequest with sensible defaults
 */
export function openXHR(
    url: string,
    method: string,
    responseType?: XMLHttpRequestResponseType,
    timeoutMS: number = 60 * 1000,
    synchronous = false,
    extraHeaders: {[key: string]: string} | null = null
): XMLHttpRequest {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url, !synchronous);

    if (extraHeaders) {
        for (const key of Object.keys(extraHeaders)) {
            xhr.setRequestHeader(key, extraHeaders[key]);
        }
    }

    // include csrf token if not "safe" method
    if (!csrfSafeMethod(method)) {
        const cookie = getCookie("csrftoken");
        if (cookie) {
            xhr.setRequestHeader("X-CSRFToken", cookie);
        }
    }

    xhr.timeout = timeoutMS;  // NOTE: See CORE-1853 before lowering this value
    if (responseType && Object.prototype.hasOwnProperty.call(xhr, "responseType") /* IE */) {
        xhr.responseType = responseType;
    }

    return xhr;
}

/**
 * Send the XMLHttpRequest with specified payload,
 * and return a promise that will resolve when the request
 * completes.
 */
export function sendXHR(
    request: XMLHttpRequest,
    payload?: string|FormData
): Promise<XMLHttpRequest> {
    return new Promise((resolve, reject) => {
        const SENT = 2;
        const OPENED = 1;
        const DONE = 4;
        if (request.readyState >= SENT) {
            reject("XMLHttpRequest already sent");
        }
        if (request.readyState < OPENED) {
            reject("XMLHttpRequest not ready for sending");
            return;
        }
        request.addEventListener("readystatechange", () => {
            if (request.readyState === DONE) {
                if (request.status === 200) {
                    resolve(request);
                } else {
                    reject(request);
                }
            }
        });
        request.addEventListener("error", () => {
            reject(`onerror: ${request.statusText}`);
            return;
        });
        request.send(payload);
    });
}

/**
 * Performs an asynchronous "GET" of the given endpoint.
 * @param url URL endpoint to use
 */
export function doGet(url: string, timeoutMS: number = 60 * 1000): Promise<XMLHttpRequest> {
    return doXHR(url, "GET", undefined, "text", timeoutMS);
}

/**
 * Performs an asynchronous "GET" of the given endpoint, with the response type
 * expected to be a JSON string.
 * @param url URL endpoint to use
 */
export async function getJson(url: string): Promise<object> {
    const xhr = await doGet(url);
    return JSON.parse(xhr.responseText);
}

/**
 * Performs an asynchronous "GET" of the given endpoint, with the response type
 * expected to be a plaintext string.
 * @param url URL endpoint to use
 */
export async function getText(url: string): Promise<string> {
    const xhr = await doGet(url);
    return xhr.responseText;
}

/* https://stackoverflow.com/a/7616484 (modified) */
export function hashString(str: string): string {
    let hash = 0;
    let i = 0;
    if (str.length === 0) {
        return "0";
    }
    for (i = 0; i < str.length; i++) {
        /* tslint:disable */
        hash = ((hash << 5) - hash) + str.charCodeAt(i);
        hash |= 0;
        /* tslint:enable */
    }
    return String(hash);
}

/**
 * Performs an asynchronous "POST" of the given endpoint.
 * @param url URL endpoint to use
 * @param payload (optional) payload to submit
 */
export function doPost(url: string, payload?: object|FormData|string, timeoutMS: number = 60 * 1000, synchronous = false): Promise<XMLHttpRequest> {
    // additional object test needed for eslint
    if (payload instanceof FormData || typeof payload === "string") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TS2345: Argument of type 'string | object | FormData | undefined'
        // is not assignable to parameter of type 'string | FormData | undefined'.
        // Type 'object' is not assignable to type 'string | FormData | undefined'
        // must be a bug, as the type is explicitly filtered
        return doXHR(url, "POST", payload, undefined, timeoutMS, synchronous);
    }
    return doXHR(url, "POST", JSON.stringify(payload), undefined, timeoutMS, synchronous);
}

/**
 * mergePromiseResponse takes an arbitrary promise, and channels both the
 * `resolve` and `reject` cases into the function's successful `resolve` case.
 * For example, a promise that rejects with value "123" will, when wrapped with
 * this function, resolve with value "123".
 * @param promiseResult a promise resolving (or rejecting) to any value
 */
export function mergePromiseResponse<T>(promiseResult: Promise<T>): Promise<T> {
    return new Promise(resolve => {
        promiseResult.then(val => { resolve(val); }).catch(val => { resolve(val); });
    });
}

/**
 * throwOnUnhandled awaits `promise`, and either resolves with the value,
 * or throws an exception, should an exception be raised.
 * This is unique in that it throws within a `setTimeout` of 0 ms, to escape
 * the asynchronous context.
 * @param promise promise to await
 */
export async function throwOnUnhandled<T>(promise: Promise<T>) {
    try {
        await promise;
    } catch (ex) {
        setTimeout(() => { throw ex; }, 0);
    }
}

/**
 * throwOnUnhandledDecorator returns an encapsulated function that calls
 * `throwOnUnhandled` with the return value of `fn`.
 * @param fn function to decorate
 */
export function throwOnUnhandledDecorator<T, P>(fn: (...args: T[]) => Promise<P>) {
    return async function(...args: T[]) {
        return await throwOnUnhandled(fn(...args));
    };
}

// converts a DICOM DA VR to a human readable date (in the same format as the
// rest of the vault). Returns input on failure.
// eg 20030201 becomes 01-Feb-2003
export function humanDA(DA: string): string {
    if (DA === "") {
        return "";
    }

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    try {
        const d = DA.slice(6, 8);
        const m = parseInt(DA.slice(4, 6), 10);
        const y = DA.slice(0, 4);

        if (!m || !d || !y) {
            return DA;
        }

        return `${d}-${months[m - 1]}-${y}`;
    } catch (err) {
        return DA;
    }
}

/**
 * Generates a human-friendly string representing the number of bytes.
 * @param numBytes number of bytes to count
 */
export function humanBytes(numBytes: number): string {
    if (numBytes === 0) {
        return "0 B";
    }
    const exponent = Math.floor(Math.log(numBytes) / Math.log(1024));
    return parseInt((numBytes / Math.pow(1024, exponent)).toFixed(2), 10) * 1 + " " + ["B", "KB", "MB", "GB", "TB"][exponent];
}

const hasHighResTimestamp = Boolean(window.performance && window.performance.now);
/**
 * nowMS returns a timestamp measured in milliseconds.
 * If a high-resolution timestamp function is available, it will be used.
 * Else, a fallback is used.
 */
export function nowMS(): number {
    return (hasHighResTimestamp ? performance.now() : Date.now());
}

/**
 * Removes a class from a given HTML Element. Will not do anything if the
 * element does not contain such a class.
 * @param element HTML Element to access
 * @param className class name to remove
 */
export function removeClass(element: HTMLElement, className: string) {
    const r = new RegExp(`(\\s|^)${className}(\\s|$)`);
    element.className = element.className.replace(r, "");
}

/**
 * Adds a class to the given HTML Element. Will not do anything if the
 * element already contains such a class.
 * @param element HTML Element to access
 * @param className class name to add
 */
export function addClass(element: HTMLElement, className: string) {
    // check if `element` contains `className`:
    if (element.className.indexOf(className) > -1) {
        return;
    }
    if (element.className.length === 0) {
        element.className = className;
    } else {
        element.className += " " + className;
    }
}

// TR creation is notoriously difficult in IE9. the `innerHTML` property of a TR
// is marked as "read only". and whilst not actually being read only, the innerHTML's
// HTML tags are stripped resulting in concatenated `textContent`s.
export function createTR(innerHTML: string): HTMLTableRowElement {
    const rowHTML = `<table><tr>${innerHTML}</tr></table>`;
    const element = document.createElement("div");
    element.innerHTML = rowHTML;
    const rowElement = element.querySelector("tr");
    if (!rowElement) {
        throw new Error("could not create TR element");
    }
    return rowElement;
}

/**
 * sleep returns with a promise that resolves after `ms` milliseconds.
 * @param ms milliseconds to sleep for
 */
export function sleep(ms: number) {
    return new Promise(r => setTimeout(r, ms));
}

/**
 * withExecLock takes a lockable resource, and an async function, and ensures
 * that, whilst the function is running, it won't be re-executed from the
 * same scope. Once the function finishes, the lockable is released.
 *
 * If the resource is locked, `null` is returned.
 *
 * @param lockable a lockable resource
 * @param fn asynchronous function to await for completion
 */
export async function withExecLock<T>(lockable: Lock, fn: () => Promise<T>) {
    if (lockable.isLocked()) {
        return null;
    }
    lockable.acquire();
    try {
        const returnValue = await fn();
        return returnValue;
    } finally {
        lockable.release();
    }
}

/**
 * Lock represents a lockable primitive.
 */
export class Lock {
    private lock: boolean;
    public isLocked() {
        return this.lock;
    }
    public acquire() {
        if (this.lock) {
            return false;
        }
        this.lock = true;
        return true;
    }
    public release() {
        this.lock = false;
    }
}

export function capitalize(phrase: string) {
    if (typeof phrase !== "string") {
        return;
    }
    else {
        return phrase.charAt(0).toUpperCase() + phrase.slice(1);
    }
}

interface QueryArgs {
    [key: string]: string;
}

export function parseQueryString(locationSearch: string = location.search): QueryArgs {
    const configDict: QueryArgs = {};
    const qs = (
        locationSearch.startsWith("?")
            ? locationSearch.slice(1)
            : locationSearch
    );
    const pairs = qs.split("&");
    for (const p of pairs) {
        const m = p.match(/([^=]+)(?:=(.*))?/);
        if (m) {
            configDict[decodeURIComponent(m[1])] = decodeURIComponent(m[2] || "1");
        }
    }
    return configDict;
}

/**
 * Fetch a server resource with a partial data callback.
 *
 * Respects the "Retry-After" header sometimes sent by the vault.
 *
 * @param path the resource to fetch
 * @param contentLengthCallback callback for the "Content-Length" header
 * @param progressCallback callback for partial data retrieved.
 * @returns HTTP status code
 */
export async function fetchWithFeedback(
    path: string,
    contentLengthCallback: (l: number) => void,
    progressCallback: (chunk: Uint8Array) => void
) {
    let retryAfter: string | null = null;
    let response: Response;
    do {
        // although MDN says "same-origin" is the default, it seemingly is not for Safari on high sierra!
        response = await fetch(path, { credentials: "same-origin", });
        retryAfter = response.headers.get("Retry-After");
        if (retryAfter !== null) {
            await sleep(parseInt(retryAfter, 10) * 1000);
        }
    } while (retryAfter !== null);
    const body = response.body;
    const contentLengthHeader = response.headers.get("Content-Length") || response.headers.get("X-Content-Length");
    if (contentLengthHeader) {
        contentLengthCallback(parseInt(contentLengthHeader, 10));
    }
    if (!response.ok) {
        return response.status;
    }
    if (body === null) {
        console.error(`response for ${path} had an empty body!`);
        return;
    }
    const reader = body.getReader();
    let done = false;
    do {
        const readResult = await reader.read();
        progressCallback(readResult.value || new Uint8Array());
        done = readResult.done;
    } while (!done);
    return response.status;
}

/**
 * A human-readable megabytes output for a number of bytes.
 *
 * @param val number of bytes
 * @returns string
 */
export function mb(val: number) {
    const valMB = val / 1024 / 1024;
    return `${Math.round(valMB * 10.0) / 10.0}MB`;
}
